<template>
  <div>
    <div class="list-edit" v-show="listshow">
      <div class="top">
        <el-button type="primary" @click="backpage" icon="el-icon-back">返回</el-button>
      </div>
      <div class="select">
        <el-form
          :rules="srules"
          ref="listrules"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
          :model="ListConfigure"
        >
          <el-form-item prop="Title" label="文章标题:">
            <el-input
              v-model="ListConfigure.Title"
              placeholder="请输入文章标题"
              class="listinput"
            ></el-input>
          </el-form-item>
          <el-form-item label="副标题:">
            <el-input
              v-model="ListConfigure.SubTitle"
              placeholder="请输入副标题"
              class="listinput"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-form
          :rules="rules"
          ref="listrules1"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
          :model="Select"
        >
          <el-form-item prop="value" label="文章类型:">
            <el-cascader
              class="listinput"
              v-model="Select.value"
              :options="Alltype"
              :props="AlltypeObj"
              @change="seltype"
              placeholder="请选择"
              :show-all-levels="false"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item
            style="width: 655px"
            label="落地页是否显示:"
            type="number"
          >
            <el-radio v-model="radio" @change="changeradio0" label="0"
              >不显示</el-radio
            >
            <el-radio v-model="radio" @change="changeradio1" label="1"
              >显示</el-radio
            >
          </el-form-item>
        </el-form>

        <el-form
          :model="ListConfigure"
          :rules="rules"
          ref="listrules2"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
        >
          <el-form-item prop="city" label="城市:">
            <el-select
              v-model="Select.value1"
              multiple
              filterable
              placeholder="请选择"
              class="listinput"
            >
              <el-option
                v-for="item in Allcity"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="Author" label="文章作者:">
            <el-input
              v-model="ListConfigure.Author"
              placeholder="请输入文章作者"
              class="listinput"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-form
          :model="ListConfigure"
          :rules="rules"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
        >
          <el-form-item label="是否推荐到:">
            <el-select
              v-model="value2"
              multiple
              placeholder="否"
              class="listinput"
            >
              <el-option
                v-for="item in optj"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="热点标签:" class="hotbut">
            <!-- listinput hot-->
            <el-select
              v-model="value3"
              :multiple-limit="2"
              filterable
              multiple
              placeholder="请选择"
              class="listinput"
            >
              <el-option
                v-for="item in Hotpats"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="addhot" v-if="false"
              >新增标签<i class="el-icon-plus el-icon--right"></i
            ></el-button>
          </el-form-item>
        </el-form>

        <el-form
          :model="ListConfigure"
          :rules="rules"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
        >
          <el-form-item label="关键词:">
            <el-input
              v-model="ListConfigure.Keywords"
              placeholder="请输入关键词"
              class="listinput"
            ></el-input>
          </el-form-item>
          <el-form-item label="摘要:">
            <el-input
              class="listinput"
              type="textarea"
              :rows="5"
              maxlength="200"
              v-model="ListConfigure.Abstract"
              placeholder="请输入摘要"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form
          :model="ListConfigure"
          :rules="rules"
          label-width="210px"
          label-position="right"
          :inline="true"
          class="demo-form-inline"
        >
          <el-form-item style="width: 655px" label="是否显示:" type="number">
            <el-radio
              v-model="radio1"
              @change="changeRadio(false)"
              :label="false"
              >不显示</el-radio
            >
            <el-radio v-model="radio1" @change="changeRadio(true)" :label="true"
              >显示</el-radio
            >
          </el-form-item>
        </el-form>
        <el-form
          :model="ListConfigure"
          :rules="rules"
          label-width="210px"
          label-position="right"
          class="demo-form-inline"
          ref="listrules3"
        >
          <el-form-item label="封面图:">
            <el-upload
              :show-file-list="false"
              :action="img_Url"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              @click="clickimg"
            >
              <el-button type="primary">
                上传<i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="封面地址:" prop="ConverImageURL">
            <el-input
              v-model="ListConfigure.ConverImageURL"
              placeholder="封面地址"
              class="listinput"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="list-context">
        <p style="color: #606266; padding-left: 140px">
          <i style="margin-left:-12px;">*</i><span>文章内容:</span>
        </p>
        <div ref="editor" id="div1"></div>
        <div class="button">
          <el-button type="primary" @click="preview">预览</el-button>
          <el-button
            type="primary"
            @click="submit('listrules')"
            :disabled="isDisabled"
            >立即提交</el-button
          >
          <el-button @click="resetForm">重置</el-button>
        </div>
      </div>
      <!-- 新增热点标签 -->
      <div class="formdia">
        <el-dialog
          :modal-append-to-body="false"
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
        >
          <span class="isedit">新增标签</span>
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="标签名称:"
              :label-width="formLabelWidth"
              prop="LabelName"
            >
              <el-input
                style="width: 340px"
                v-model="Rowcontent.LabelName"
                placeholder="请输入标签名称，不能超过20个字"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="链接关键词:"
              :label-width="formLabelWidth"
              prop="KeyWord"
            >
              <el-input
                style="width: 340px"
                v-model="Rowcontent.KeyWord"
                placeholder="请输入链接关键词，不能超过50个字"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button @click="submitForm('editForm')"> 确定 </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="Listpreview" v-show="!listshow">
      <el-button type="primary" @click="listshow = true" icon="el-icon-back"
        >返回</el-button
      >
      <div class="previewList">
        <h1>{{ ListConfigure.Title }}</h1>
        <p>
          <span class="author">作者：{{ ListConfigure.Author }}</span>
          <span class="time">时间：{{ ListConfigure.DateCreated }}</span>
        </p>
        <div class="contentlist" v-html="ListConfigure.Content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";

export default {
  name: "List-edit",
  data() {
    var labelNameRules = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("名称只允许输入汉字、数字或字母"));
      }
    };
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      ListConfigure: {},
      Rowcontent: {
        LabelName: "",
        KeyWord: ""
      },
      Select: {
        value: "", //类别显示
        value1: [] //城市
      },
      rules: {
        LabelName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          { validator: labelNameRules, trigger: "blur" }
        ],
        KeyWord: [
          { required: true, message: "请输入链接关键词", trigger: "blur" }
        ],
        value: [
          { required: true, message: "请选择文章类型", trigger: "change" }
        ],
        city: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        Author: [{ required: true, message: "请输入作者", trigger: "blur" }],
        ConverImageURL: [
          { required: true, message: "请输入封面链接", trigger: "blur" }
        ]
      },
      srules: {
        Title: [{ required: true, message: "请输入文章标题", trigger: "blur" }]
      },

      formInline: {
        user: "",
        region: "",
        desc: ""
      },
      Alltype: [], //获取所有分类
      AlltypeObj: {
        value: "CategoryGuid",
        label: "Name",
        children: "Children",
        checkStrictly: true
      }, //获取所有分类
      Allcity: [], //所有城市
      radio: "", //是否落地
      radio1: true, //是否显示
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image", //封面图
      Hotpats: [],
      optj: [
        {
          value: "选项1",
          label: "头条聚焦"
        },
        {
          value: "选项2",
          label: "热门资讯"
        },
        {
          value: "选项3",
          label: "HR百科"
        },
        {
          value: "选项4",
          label: "首页动态"
        }
      ],
      value2: [],
      value3: [],
      editorContent: "",
      editor: null,
      isDisabled: false, //防抖
      listshow: true,
      SumbitList: {
        Abstract: "",
        Author: "",
        CategoryGuid: "",
        //CategoryName: "",
        City: "",
        Content: "",
        ConverImageURL: "",
        Creator: "",
        IsHot: false,
        IsShow: true,
        IsUsedLandingPage: true,
        Keywords: "",
        Labels: "",
        RecommendPosition: "",
        SubTitle: "",
        Title: ""
      }
    };
  },
  watch: {
    $route(to, from) {
      if (to.name == "ListEdit") {
        this.$router.go(0); //监测到路由发生跳转时刷新一次页面
      }
    }
  },
  created() {
    this.getDate();
    this.listshow = true;
  },
  mounted() {
    this.getalltype();
    this.editor = new E(this.$refs.editor);
    this.editor.config.zIndex = 1;
    this.editor.config.uploadImgServer = this.img_Url;
    this.editor.config.uploadImgHooks ={
      // 图片上传并返回了结果，图片插入已成功
      success: function(xhr) {
          console.log('success', xhr)
      },
    }
    this.editor.config.uploadImgHooks = {
        customInsert: function (insertImg, result, editor2) {
          let uploadimg = '';
          if (result.isSucc) {
            uploadimg = result.success_list[0];
          }
          insertImg(uploadimg);
        },
      };
    // console.log(this.editor)
    // this.editor.customConfig.uploadImgShowBase64 = true // 使用base64保存图片  上下两者不可同用
    // 隐藏“网络图片”tab
    this.editor.create();
    this.choosecity();
    this.hotspot();
  },
  methods: {
    getDate() {
      var _this = this;
      const listId = this.$route.query.soid;
      //console.log(listId);
      if (listId == "add") {
        //console.log("add");
        _this.ListConfigure = _this.SumbitList;
      } else {
        this.$get(_this.$api.GetArticleDTO + "?id=" + listId)
          .then(res => {
            _this.ListConfigure = res;
            // console.log(_this.ListConfigure);
            //是否推荐
            if (!_this.ListConfigure.RecommendPosition) {
              //  console.log("norecommedd");
            } else {
              for (
                let i = 0;
                i < _this.ListConfigure.RecommendPosition.split(",").length;
                i++
              ) {
                _this.value2.push(
                  _this.ListConfigure.RecommendPosition.split(",")[i]
                );
              }
            }
            //落地页是否显示
            if (_this.ListConfigure.IsUsedLandingPage == false) {
              _this.radio = "0";
            } else {
              _this.radio = "1";
            }
            //是否显示
            _this.radio1 = _this.ListConfigure.IsShow;
            //热点标签
            if (_this.ListConfigure.Labels != "") {
              for (
                let i = 0;
                i < _this.ListConfigure.Labels.split(",").length;
                i++
              ) {
                _this.value3.push(_this.ListConfigure.Labels.split(",")[i]);
              }
            } else {
              _this.value3 = [];
            }
            //文章类型
            _this.Select.value = _this.ListConfigure.CategoryGuid;
            //内容
            this.editor.txt.html(_this.ListConfigure.Content);
            let name2 = JSON.parse(JSON.stringify(_this.ListConfigure.City));
            let name3 = name2.split(",");
            for (let i = 0; i < name3.length; i++) {
              _this.Select.value1.push(name3[i]);
            }
            // console.log(this.Select.value1);
          })

          .catch(err => {
            console.log(err);
          });
      }
    },
    //返回上一页
    backpage() {
      //window.location='/#/ArticleManage'
      this.$router.push({
        path: "ArticleManage"
      });
    },
    //获取所有类型
    getalltype() {
      var _this = this;
      this.$get(this.$api.GetAllArticleCategory).then(data => {
        let res = data.Data;
        _this.Alltype = res;
      });
    },
    seltype() {
      let _this = this;
      if (this.Select.value && this.Select.value.length > 1) {
        _this.ListConfigure.CategoryGuid = this.Select.value[ this.Select.value.length - 1];
      } else {
        _this.ListConfigure.CategoryGuid = this.Select.value[0];
      }
    },

    //单选框
    changeradio0() {
      this.ListConfigure.IsUsedLandingPage = "false";
    },
    changeradio1() {
      this.ListConfigure.IsUsedLandingPage = "true";
    },
    changeRadio(val) {
      this.ListConfigure.IsShow = val;
    },

    //选择城市
    choosecity() {
      var _this = this;
      this.$get(this.$api.GetCityDropDownList).then(res => {
        for (let i = 0; i < res.length; i++) {
          let a = res[i];
          let b = res[i];
          _this.Allcity.push({ label: a, value: a, id: b });
        }
      });
    },

    //热点标签
    hotspot() {
      var _this = this;
      this.$get(this.$api.GetAllHotLabel).then(res => {
        for (let i = 0; i < res.length; i++) {
          let a = res[i].LabelName;
          let b = res[i].Id;
          _this.Hotpats.push({ label: a, value: a, id: b });
        }
      });
    },
    //添加热点标签
    addhot() {
      this.dialogFormVisible = true;
      this.Rowcontent.LabelName = "";
      this.Rowcontent.KeyWord = "";
    },

    //热点标签提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    async submitForm(editForm) {
      this.isDisabled = true;
      this.entime();
      var _this = this;
      this.$refs[editForm].validate(valid => {
        if (valid) {
          console.log("新增");
          this.$post(this.$api.AddHotLabel, this.Rowcontent)
            .then(res => {
              if (res.IsSuccess) {
                _this.$message({
                  message: "新增成功",
                  type: "success",
                  duration: "1500",
                  offset: 200
                });
                _this.Hotpats = [];
                _this.hotspot();
              } else {
                _this.$message({
                  message: res.Message,
                  type: "warning",
                  duration: "1500",
                  offset: 200
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
          this.dialogFormVisible = false;
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
            offset: 200
          });
        }
      });
    },
    //动态加载热点标签

    //预览
    preview() {
      this.listshow = false;
      this.ListConfigure.Content = this.editor.txt.html();
    },

    //封面
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.ListConfigure.ConverImageURL = res.success_list[0];
    },
    beforeAvatarUpload() {},
    clickimg() {},
    //重置
    resetForm() {
      this.ListConfigure.Title = "";
      this.ListConfigure.SubTitle = "";
      this.Select.value = "";
      this.Select.value1 = [];
      this.value2 = [];
      this.value3 = [];
      this.ListConfigure.Author = "";
      this.ListConfigure.Keywords = "";
      this.ListConfigure.Abstract = "";
      this.ListConfigure.ConverImageURL = "";
      this.editor.txt.html("");
    },
    //提交
    submit(a) {
      var _this = this;
      if (this.Select.value1 == "" || this.Select.value1 == null) {
        this.$message({
          message: "请选择城市！",
          type: "error",
          duration: "1500",
          offset: 200
        });
        return;
      }
      if (
        this.editor.txt.html().length == 1 ||
        this.editor.txt.html().length == 0
      ) {
        this.$message({
          message: "请填写文章内容！",
          type: "error",
          duration: "1500",
          offset: 200
        });
        return;
      }
      if (
        this.ListConfigure.Author == "" ||
        this.ListConfigure.ConverImageURL == "" ||
        this.Select.value == ""
      ) {
        this.$message({
          message: "请完成信息填写！",
          type: "error",
          duration: "1500",
          offset: 200
        });
        return;
      }
      this.$refs[a].validate(valid => {
        if (valid) {
          this.SumbitList = this.ListConfigure;
          // this.SumbitList.CategoryGuid = this.Select.value; //类别
          this.SumbitList.City = this.Select.value1.toString(); //城市
          this.SumbitList.RecommendPosition = this.value2.toString(); //是否推荐到

          this.SumbitList.Labels = this.value3.toString(); //热点标签
          this.SumbitList.Content = this.editor.txt.html(); //编辑内容
          // console.log(this.Select.value);
          // console.log(this.SumbitList);
          // console.log(this.ListConfigure.CategoryGuid)
          this.$confirm("是否提交", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "success"
          }).then(() => {
            console.log("ok");
            // console.log(this.SumbitList)
            _this
              .$post(_this.$api.NewOrEditArticle, _this.SumbitList)
              .then(res => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                    offset: 200
                  });
                  _this.backpage();
                }
              })
              .catch(err => {
                console.log(err);
              });
          });
        } else {
          console.log("no");
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
            offset: 200
          });
          return;
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.list-edit {
  width: 100%;
  height: 100%;
  min-width: 700px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 20px;

  .top {
    button {
      margin: 20px 20px;
    }
  }

  .select {
    .listinput {
      width: 400px;
    }

    .listinput.hot {
      width: 300px;
    }

    .hotbut {
      button {
        margin-left: 10px;
        width: 90px;
        padding-left: 10px;
      }
    }
  }

  .list-context {
    p {
      i {
        color: #F56C6C;
        position: absolute;
        // left: 346px;
      }
    }

    #div1 {
      width: 88%;
      margin: 0 auto;
      margin-top: 20px;
    }

    .button {
      margin: 20px;
      margin-left: 140px;
    }
  }
}

.Listpreview {
  width: 100%;
  height: 100%;
  background: #ffffff;

  button {
    margin: 20px 30px;
  }

  p {
    text-align: center;

    .author {
      padding-right: 30px;
    }
  }

  .contentlist {
    margin: 10px auto;
    width: 90%;
    word-wrap: break-word;
    word-break: normal;
    padding-bottom: 30px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  span.isedit {
    position: absolute;
    top: 10px;
    left: 210px;
    font-size: 22px;
    color: #fff;
  }

  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>
